<template>
    <div class="about">
        <el-container>
            <el-header>About page</el-header>
            <el-container>
                <el-aside width="200px">
                    Aside
                    <el-menu :default-openeds="['1', '3']">
                        <el-submenu index="1">
                            <template #title>
                                <i class="el-icon-message"></i>Navigator One
                            </template>
                            <el-menu-item-group>
                                <template #title>
                                    Group 1
                                </template>
                                <el-menu-item index="1-1">Option 1</el-menu-item>
                                <el-menu-item index="1-2">Option 2</el-menu-item>
                            </el-menu-item-group>
                            <el-menu-item-group title="Group 2">
                                <el-menu-item index="1-3">Option 3</el-menu-item>
                            </el-menu-item-group>
                            <el-submenu index="1-4">
                                <template #title>
                                    Option4
                                </template>
                                <el-menu-item index="1-4-1">Option 4-1</el-menu-item>
                            </el-submenu>
                        </el-submenu>
                        <el-submenu index="2">
                            <template #title>
                                <i class="el-icon-menu"></i>Navigator Two
                            </template>
                            <el-menu-item-group>
                                <template #title>
                                    Group 1
                                </template>
                                <el-menu-item index="2-1">Option 1</el-menu-item>
                                <el-menu-item index="2-2">Option 2</el-menu-item>
                            </el-menu-item-group>
                            <el-menu-item-group title="Group 2">
                                <el-menu-item index="2-3">Option 3</el-menu-item>
                            </el-menu-item-group>
                            <el-submenu index="2-4">
                                <template #title>
                                    Option 4
                                </template>
                                <el-menu-item index="2-4-1">Option 4-1</el-menu-item>
                            </el-submenu>
                        </el-submenu>
                        <el-submenu index="3">
                            <template #title>
                                <i class="el-icon-setting"></i>Navigator Three
                            </template>
                            <el-menu-item-group>
                                <template #title>
                                    Group 1
                                </template>
                                <el-menu-item index="3-1">Option 1</el-menu-item>
                                <el-menu-item index="3-2">Option 2</el-menu-item>
                            </el-menu-item-group>
                            <el-menu-item-group title="Group 2">
                                <el-menu-item index="3-3">Option 3</el-menu-item>
                            </el-menu-item-group>
                            <el-submenu index="3-4">
                                <template #title>
                                    Option 4
                                </template>
                                <el-menu-item index="3-4-1">Option 4-1</el-menu-item>
                            </el-submenu>
                        </el-submenu>
                    </el-menu>
                </el-aside>
                <el-main>Details</el-main>
            </el-container>
            <el-footer>Footer</el-footer>
        </el-container>
    </div>
</template>
<style>
    .el-header, .el-footer {
        border: 1px solid #000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .el-aside {
        background-color: #B3C0D1;
        color: #fff;
    }

    .el-main {
        background-color: #ddd;
    }
</style>
